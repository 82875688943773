<template>
  <section class="section__container">
    <div class="section__container_background">
      <img
        max-width="100%"
        height="100%"
        src="/backgrounds/page_quemsomos_al.png"
        alt=""
      />

      <GoBack />
    </div>
    <div class="container position--relative">
      <Breadcrumb style="color: white;" class="sobre_section_bc-color">
        <template v-slot:breadcrumbLinks>
          <router-link
            tag="p"
            :to="{ name: link.href }"
            v-for="(link, idx) in breadcrumbItems"
            :key="idx"
          >
            {{ link.text }}
          </router-link>
        </template>
      </Breadcrumb>
      <v-row class="d-flex flex-row-reverse">
        <v-col cols="12" md="7">
          <Card
            title="Cáritas América Latina e Caribe"
            borderColor="#C92F05"
            titleColor="#C92F05"
            backgroundColor="#F9F7ED"
          >
            <template v-slot:conteudo>
              <p>
                A Cáritas da América Latina e Caribe possui a missão de
                vivificar, segundo a luz do Evangelho e Doutrina Social da
                Igreja, o processo de transformação da realidade dos povos da
                América Latina, com o protagonismo dos pobres e excluídos em
                harmonia com a criação de uma sociedade justa, fraterna e
                solidária, respeitando o contexto de cada país da região. Assim,
                a Cáritas da América Latina e Caribe reconhece como sua missão,
                a construção de uma região solidária, justa, democrática e
                pluralista, onde os poderes públicos sejam representativos,
                transparentes e participativos para que cada homem e cada mulher
                vivam de acordo com a sua dignidade, tenham satisfeitas suas
                necessidades básicas e que usem de forma sustentável os recursos
                para a criação do bem comum. A Cáritas da América Latina e
                Caribe busca caminhos para construir a equidade e a justiça para
                o povo latino americano e caribenho.
              </p>
              <p>
                A rede propõe um importante espaço de discussão e reflexão
                acerca dos diversos desafios impostos aos povos desta região,
                especialmente, os relacionados aos altos índices de desigualdade
                social e consequente baixo índice de desenvolvimento (IDH).
                Assim, os entes federados se unem em busca de troca de
                experiências e promoção de planos de ação em conjunto. Desta
                forma, temas como migração, novas economias (solidárias),
                democracia, governabilidade e emergências perpassam os eixos de
                atuação da região América Latina e Caribe.
              </p>
            </template>

            <template v-slot:footerImage>
              <div class="card__footer_img mt-3">
                <img width="50%" src="/logos/logo_america_latina.png" alt="" />
              </div>
            </template>
          </Card>
        </v-col>
      </v-row>
    </div>
    <div class="container">
      <v-row class="d-flex flex-row-reverse">
        <v-col sm="12" md="7" class="card__footer_btn">
          <div
            @click="navigateTo('sobre_caritas_brasileira')"
            class="white--text pa-3 nav__button_intern"
          >
            Conhecer "Cáritas Brasileira"
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import GoBack from "@/components/GoBack.vue";

export default {
  components: {
    Card,
    Breadcrumb,
    GoBack,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "<       Quem somos       /",
          href: "cards",
        },
        {
          text: "Cáritas Internacional /",
          href: "sobre_caritas_internacional",
        },

        {
          text: "Cáritas América Latina e Caribe",
          href: "sobre_caritas_al",
        },
      ],
    };
  },
  methods: {
    navigateTo(route) {
      return this.$router.push({ name: route });
    },
  },
  created() {},
};
</script>

<style scoped>
.v-btn {
  width: 80%;
  word-wrap: normal;
  white-space: nowrap;
  text-align: center;
}
.section__container {
  position: relative;
}

.section__container_background {
  position: fixed;
  top: 0;
}

@media (max-width: 600px) {
  .section__container_background {
    position: relative;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 1400px) {
  .section__container_background {
    display: flex;
    justify-content: center;
  }
}

.position--relative {
  position: relative;
}

.card__footer_img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card__footer_img img {
  width: 40%;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}
</style>
